@import "../../styles/01.settings/01-settings-colors.module.scss";

.SenderContainer {
    justify-content: flex-end;
    padding: 5px;
}
.RecipientContainer {
    justify-content: flex-start;
    padding: 5px;
}

.Sender {
    position: relative;
    background: $teal;
    padding: 0px 5px 0px 5px;
    color: $white;

    border-radius: 0.4em;
}

.Recipient {
    position: relative;
    background: $success;
    padding: 0px 5px 0px 5px;
    color: $white;
    border-radius: 0.4em;
}

.Recipient:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: $success;
    border-left: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-left: -20px;
}

.Sender::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: $teal;
    border-right: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-right: -20px;
}
