@import "../../../styles/01.settings/01-settings-colors.module.scss";
@import "../../../styles/01.settings/settings-breakpoints";

.DropzoneAreaWrapper {
  div:first-child {
    margin: 0;
    overflow: visible;
    background-color: white;
    width: 30px;
    min-height: 20px !important;
    border: none;
    border-radius: 50%;
  }
}
