@import "../../styles/01.settings/01-settings-colors.module.scss";

.Root {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-end;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;

    legend {
        color: $dark;
    }
    .FormControlClass {
        border: 2px solid $dark;
        border-radius: 10px;
        margin: 24px;
        padding: 15px;
        color: $dark;
    }
}
