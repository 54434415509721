@import "../styles/01.settings/all-settings";
@import "../styles/04.elements/elements-links";
@import "../styles/button-styles";
@import "../styles/DreamClasses/all-classes";
@import "../styles/01.settings/01-settings-colors.module.scss";

// .App {
//   width: 100%;
// }
.MessagesCount {
  span {
    color: $white;
    background-color: $danger;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

// ::-webkit-scrollbar-track {
//   background: $primary;
// }

// ::-webkit-scrollbar-thumb {
//   background-image: linear-gradient($primary, white);
//   border-radius: 25px;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-image: linear-gradient($primary, white);
// }
