@import "../../styles/01.settings/01-settings-colors.module.scss";

.DreamDialogRoot {
    display: flex;
    justify-content: space-between;
    padding-top: 0;

    h6 {
        display: flex;
        align-items: center;
    }

    .CloseButton {
        justify-self: end;
        &__Icon {
            color: $gray;
        }
    }
}
