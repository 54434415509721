$buttonShadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
$buttonHoverShadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
$headerSettingsMenuShadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
$dreamDynamicGridPaperItem: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 0px 0px rgba(0, 0, 0, 0.12);

:export {
    buttonShadow: $buttonShadow;
    buttonHoverShadow: $buttonHoverShadow;
    headerSettingsMenuShadow: $headerSettingsMenuShadow;
    dreamDynamicGridPaperItem: $dreamDynamicGridPaperItem;
}
