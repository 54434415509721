.Root {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Inner {
    margin: auto;
    padding-top: 20em;
    padding-bottom: 20em;

    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    max-width: 300px;
}
