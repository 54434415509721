@mixin DreamButton(
    $bgColor,
    $borderColor,
    $textColor,
    $boxShadow,
    $bgColorHover,
    $borderColorHover,
    $textColorHover,
    $boxShadowHover
) {
    border: 1px solid $borderColor;
    color: $textColor;
    background-color: $bgColor;
    box-shadow: $boxShadow;

    &:hover {
        border: 1px solid $borderColorHover;
        color: $textColorHover;
        background-color: $bgColorHover;
        box-shadow: $boxShadowHover;
        transform: $buttonTransformHover;
    }

    &:disabled {
        @if ($borderColor== "none") {
            background-color: $white;
        } @else {
            background-color: lighten($bgColor, 25%);
        }
        border-color: $transparent !important;
        color: $gray;
        box-shadow: none;
    }
}

// $colorAsString are as follows : "bg" for background, "border" for border,"font" for text color
@mixin BgBorderFont_Color_ClassGenerator($property, $colorAsString, $color) {
    .Dream-#{$property}--#{$colorAsString} {
        @if $property == "bg" {
            background-color: $color !important;
        } @else if $property == "border" {
            border: 1px solid $color !important;
        } @else if $property == "font" {
            color: $color !important;
        }
    }
}
