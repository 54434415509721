/* DreamButton */
.DreamButton {
    // position: relative !important;
    // text-transform: uppercase !important;
    // will-change: transform !important;
    // letter-spacing: 0.025em !important;
    // font-size: 0.875rem !important;

    // display: inline-block !important;
    font-weight: 600 !important;
    // text-align: center !important;
    // vertical-align: middle !important;
    // user-select: none !important;
    // padding: 0.625rem 1.25rem !important;
    // line-height: 1.5 !important;
    // border-radius: 0.25rem !important;
    // transition: all 0.15s ease !important;
}

/* Mixin DreamButton parameters as follows: (background-color,border-color,text color, box-shadow,
                                            background-color:hover,border-color:hover,text-color:hover
                                            box-shadow:hover; */

/* Primary */
.DreamButton-primary.DreamButton-outlined {
    @include DreamButton($white, $primary, $primary, $buttonShadow, $primary, $primary, $white, $buttonHoverShadow);
}

.DreamButton-primary.DreamButton-contained {
    @include DreamButton(
        $primary,
        $primary,
        $white,
        $buttonShadow,
        $primaryHoverBackground,
        $primaryHoverBorder,
        $white,
        $buttonHoverShadow
    );
}

.DreamButton-primary.DreamButton-text {
    @include DreamButton($transparent, $transparent, $primary, "none", $transparent, "none", $primary, "none");
}

/* Info */
.DreamButton-info.DreamButton-outlined {
    @include DreamButton($white, $info, $info, $buttonShadow, $info, $info, $white, $buttonHoverShadow);
}

.DreamButton-info.DreamButton-contained {
    @include DreamButton(
        $info,
        $info,
        $white,
        $buttonShadow,
        $infoHoverBackground,
        $infoHoverBorder,
        $white,
        $buttonHoverShadow
    );
}

.DreamButton-info.DreamButton-text {
    @include DreamButton($transparent, $transparent, $info, "none", $transparent, "none", $info, "none");
}

/* Success */
.DreamButton-success.DreamButton-outlined {
    @include DreamButton($white, $success, $success, $buttonShadow, $success, $success, $white, $buttonHoverShadow);
}

.DreamButton-success.DreamButton-contained {
    @include DreamButton(
        $success,
        $success,
        $white,
        $buttonShadow,
        $successHoverBackground,
        $successHoverBorder,
        $white,
        $buttonHoverShadow
    );
}

.DreamButton-success.DreamButton-text {
    @include DreamButton($transparent, $transparent, $success, "none", $transparent, "none", $success, "none");
}

/* Danger */
.DreamButton-danger.DreamButton-outlined {
    @include DreamButton($white, $danger, $danger, $buttonShadow, $danger, $danger, $white, $buttonHoverShadow);
}

.DreamButton-danger.DreamButton-contained {
    @include DreamButton(
        $danger,
        $danger,
        $white,
        $buttonShadow,
        $dangerHoverBackground,
        $dangerHoverBorder,
        $white,
        $buttonHoverShadow
    );
}

.DreamButton-danger.DreamButton-text {
    @include DreamButton($transparent, $transparent, $danger, "none", $transparent, "none", $danger, "none");
}

/* Warning */
.DreamButton-warning.DreamButton-outlined {
    @include DreamButton($white, $warning, $warning, $buttonShadow, $warning, $warning, $white, $buttonHoverShadow);
}

.DreamButton-warning.DreamButton-contained {
    @include DreamButton(
        $warning,
        $warning,
        $white,
        $buttonShadow,
        $warningHoverBackground,
        $warningHoverBorder,
        $white,
        $buttonHoverShadow
    );
}

.DreamButton-warning.DreamButton-text {
    @include DreamButton($transparent, $transparent, $warning, "none", $transparent, "none", $warning, "none");
}

/* Default */
.DreamButton-default.DreamButton-outlined {
    @include DreamButton($white, $default, $default, $buttonShadow, $default, $default, $white, $buttonHoverShadow);
}

.DreamButton-default.DreamButton-contained {
    @include DreamButton(
        $default,
        $default,
        $white,
        $buttonShadow,
        $defaultHoverBackground,
        $defaultHoverBorder,
        $white,
        $buttonHoverShadow
    );
}

.DreamButton-default.DreamButton-text {
    @include DreamButton($transparent, $transparent, $default, "none", $transparent, "none", $default, "none");
}

/* Secondary */
.DreamButton-secondary.DreamButton-outlined {
    @include DreamButton($white, $secondary, $dark, $buttonShadow, $secondary, $secondary, $dark, $buttonHoverShadow);
}

.DreamButton-secondary.DreamButton-contained {
    @include DreamButton(
        $secondary,
        $secondary,
        $dark,
        $buttonShadow,
        $secondaryHoverBackground,
        $secondaryHoverBorder,
        $dark,
        $buttonHoverShadow
    );
}

.DreamButton-secondary.DreamButton-text {
    @include DreamButton($transparent, $transparent, $dark, "none", $transparent, "none", $dark, "none");
}

// $bgColor,
// $borderColor,
// $textColor,
// $boxShadow,
// $bgColorHover,
// $borderColorHover,
// $textColorHover,
// $boxShadowHover
