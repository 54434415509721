$indigo: #5603ad;
$purple: #8965e0;
$pink: #f3a4b5;
$red: #f5365c;
$orange: #fb6340;
$hpTitleOrange: #fd7a01;
$yellow: #ffd600;
$green: #2dce89;
$greenDarken: darken($green, 8);
$teal: #11cdef;
$cyan: #2bffc6;
$greyDarker: #dee1e7;
$gray: #8898aa;
$grayDark: #32325d;
$light: #ced4da;
$lighter: #e9ecef;
$lightGray: #adb5bd;
$dark: #212529;
$white: #fff;
$neutral: #fff;
$darker: #000;
$transparent: transparent;
$backdropColor: #00bfff;
$background: #f9f8ff;
$textWBG: #3c3b4d;

// Primary
$primary: #5e72e4;
$primaryLighten: lighten($primary, 8);
// Primary-contained:hover
$primaryHoverBackground: #3d55df;
$primaryHoverBorder: #324cdd;

// Info
$info: #11cdef;
// Info-contained:hover
$infoHoverBackground: #0eafcc;
$infoHoverBorder: #0da5c0;

// Success
$success: #2dce89;
// Success-contained:hover
$successHoverBackground: #24a46d;
$successHoverBorder: #229a66;

// Danger
$danger: #f5365c;
// Danger-contained:hover
$dangerHoverBackground: #f3e;
$dangerHoverBorder: #ec0c38;

// Warning
$warning: #fb6340;
// Warning-contained:hover
$warningHoverBackground: #fa441b;
$warningHoverBorder: #fa3a0e;

// Default
$default: #172b4d;
// Default-contained:hover
$defaultHoverBackground: #0e1b30;
$defaultHoverBorder: #0b1526;

// Secondary
$secondary: #f4f5f7;
// Secondary-contained:hover
$secondaryHoverBackground: #dee1e7;
$secondaryHoverBorder: #d6dae2;

:export {
  primary: $primary;
  secondary: $secondary;
  info: $info;
  success: $success;
  danger: $danger;
  warning: $warning;
  default: $default;
  indigo: $indigo;
  purple: $purple;
  pink: $pink;
  red: $red;
  orange: $orange;
  hpTitleOrange: $hpTitleOrange;
  yellow: $yellow;
  green: $green;
  greenDarken: $greenDarken;
  teal: $teal;
  cyan: $cyan;
  greyDarker: $greyDarker;
  gray: $gray;
  grayDark: $grayDark;
  light: $light;
  lighter: $lighter;
  lightGray: $lightGray;
  dark: $dark;
  white: $white;
  neutral: $neutral;
  darker: $darker;
  transparent: $transparent;
  backdropColor: $backdropColor;
  background: $background;
  text: $textWBG;
  primaryLighten: $primaryLighten;
}
